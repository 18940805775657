@import "../../../styles/common";

$article-filter-tag-icon-color: $ct-black-50;

.article-filter-form {
  &__footer {
    display: flex;
    justify-content: flex-end;
  }

  &__cancel {
    margin-right: 15px;
  }

  .form-label {
    font-size: $knowledge-base-form-font-size;
  }

  &__tag-input {
    .custom-select__indicators {
      display: none;
    }
  }

  &__tags {
    margin-bottom: 20px;
  }

  &__tag {
    margin: 0 16px 0 0;
    border: none;
    max-width: 150px;

    svg {
      color: $article-filter-tag-icon-color;
      margin-left: 5px;
    }

    .tag__title {
      padding-bottom: 2px;
    }
  }

  &__date {
    flex: 1 1 auto;
    position: relative;
    margin-bottom: 1rem;

    .close {
      position: absolute;
      right: 12px;
      top: 2px;
      font-size: 1.6rem;

      &:focus {
        outline: 0;
      }
    }
  }

  &__date-input-icon {
    position: absolute;
    pointer-events: none;
    left: 17px;
    top: 11px;
    color: var(--primary);
  }

  &__date-input {
    padding-left: 38px;
  }
}