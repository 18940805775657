// global color. DO NOT USE DIRECTLY IN SCHEMA


$ct-blue: #1066A8;
$ct-orange: #F07F0E;


$ct-gray-50: #F8F8F8;
$ct-gray-100: #ECECEC;
$ct-gray-150: #F6F6F6;
$ct-gray-175: #F2F2F2;
$ct-gray-200: #EEEEEE;
$ct-gray-250: #E2E2E2;
$ct-gray-300: #E0E0E0;
$ct-gray-450: #CECECE;
$ct-gray-500: #BDBDBD;
$ct-gray-600: #A2A2A2;
$ct-gray-650: #A3A3A3;
$ct-gray-700: #828282;
$ct-gray-850: #4F4F4F;

$ct-black-50: #333333;

$ct-green-1: #27AE60;
$ct-green-2: #00e676;
$ct-green-3: #219653;
$ct-green-4: #1A9F29;
$ct-green-5: #00BB8E;
$ct-green-6: #DEEFE5;
$ct-green-7: #A3CB38;
$ct-green-8: #2F9A41;
$ct-green-9: #4BB34B;

$ct-purple-1: #7B519D;

$ct-blue-1: #2ca5e0;
$ct-blue-2: #0099e3;
$ct-blue-3: #0EABDC;
$ct-blue-4: #1066A8;
$ct-blue-5: #0B426D;

// generates color tones for theme based on main color var
$ct-primary-1: color-mix(in oklab, var(--primary) 10%, #FFFFFF 100%);
$ct-primary-2: color-mix(in hsl, var(--primary) 100%, #F4FFE5 10%);
$ct-primary-3: color-mix(in lab, var(--primary) 10%, #FFFFFF 100%);
$ct-primary-4: color-mix(in srgb, var(--primary) 60%, #FFFFFF 100%);

// generates color tones for scenarios based on main color var
$ct-editor-1: color-mix(in srgb, var(--editor) 20%, #FFFFFF 100%);
$ct-editor-2: color-mix(in srgb, var(--editor) 40%, #FFFFFF 90%);
$ct-editor-3: color-mix(in srgb, var(--editor) 30%, #FFFFFF 100%);
$ct-editor-4: color-mix(in lch, var(--editor) 20%, #FFFFFF 100%);
$ct-editor-5: color-mix(in srgb, var(--editor) 15%, #FFFFFF 100%);

$ct-red-1: #ca0c13;
$ct-red-2: #E34646;
$ct-red-3: #E0001B;
$ct-red-4: #D22323;
$ct-red-5: #FBDBE1;
$ct-red-6: #FBE9E9;

$ct-orange-1: #D94E02;
$ct-orange-2: #EB8E39;
$ct-orange-3: #F79F1F;

$ct-yellow-1: #FFC312;
$ct-yellow-2: #F3EE64;