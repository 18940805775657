@use "../../styles/common";

.projectViewHeader {
  height: var(--header-height);
  background-color: var(--primary);
  color: white;
  display: flex;
  align-items: center;
  padding: 17px;
  justify-content: space-between;

  &__side {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 17px;
  }

  &__middle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &__dialogsCounter {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 2px;
    left: -5px;
    color: var(--primary);
    background-color: white;
  }
  &__errorText {
    margin-top: 0;
  }
}