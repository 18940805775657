@import "../../styles/common";

$header-color: $ct-gray-175;
$article-border-color: $ct-gray-300;
$articles-font-size: $ct-font-size-base;
$article-border-color: $ct-gray-300;

.searchQueryArticles {
  &__body {
    padding: 0 15px;
  }
}