@import "../../styles/common";

.questionerSlot {
    &__section {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        position: relative;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__content {
        cursor: pointer;
        min-height: 40px;
        word-break: break-word;

        &_readonly {
            overflow-y: auto;
        }

        &__readonlyLink {
            overflow-y: hidden;
        }
    }

    &__copyButton {
        background: none;
        color: $ct-gray-600;

        &:hover {
            background: none;
        }
    }

    &__inputField {
        position: relative;
        width: 100%;

        &_typeSelect {
            position: relative;
            width: 100%;
        }

        &__increasedFieldSize > textarea {
            resize: none;
            min-height: 75px;
            overflow-y: auto;
            word-break: break-word;
        }
    }

    &__title {
        flex: 0 0 30%;
        word-break: break-word;

        &::after {
            content: ":";
        }
    }

    &__actionsContainer {
        position: relative;
        display: flex;
        align-self: center;

        &_noEdited {
            visibility: hidden;
            pointer-events: none;
        }
    }

    &__actionsButton {
        background: none;
        border: none;

        svg {
            margin-left: 2px;
        }

        path {
            fill: $ct-gray-600;
        }

        &:hover {
            path {
                fill: var(--primary);
            }
        }
    }
}
