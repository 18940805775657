@use "../../styles/common" as common;

.articleActions {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  padding-top: 10px;

  &_button {
    width: 210px;
    min-height: 40px;
    &_icon {
      margin-right: 8px;
    }
  }
}
