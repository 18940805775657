@import "../../styles/common";

$operator-status-item-font-size-base: $menu-item-title-font-size;
$operator-status-default-color: $body-color;

.operatorStatusSelector {
  &_sidebar {
    margin-bottom: $menu-item-bottom-spacing * 2;
  }

  $self: &;

  &.sidebarContextMenu {
    background-color: transparent;
    padding: 0;

    &:hover {
      background-color: transparent;
    }
  }

  &__toggle {
    @include disable-default-button-styles;


  }

  &_inHeader {
    @include flex-center-row;
    align-items: center;
    gap: 5px;
    min-width: 110px;
  }

  &__container {
    align-items: center;
    justify-content: center;
  }

  &__item {
    display: flex;
    align-items: center;
    font-size: $operator-status-item-font-size-base;

    #{$self}__circle {
      @include set-operator-status-color($operator-status-default-color);
    }

    @each $status, $value in $operator-status-colors {
      &_#{$status} {
        #{$self}__circle {
          @include set-operator-status-color($value);
        }
      }
    }
  }

  &__selectedIcon {
    margin-left: 5px;
  }

  &__circle {
    padding: 4px;
    border-radius: 50%;
    border: 1px solid white;
  }

  &__context {
    &.context-menu {
      padding: 12px;
    }
  }

  &__spinner {
    color: white;
    width: 20px;
    height: 20px;
  }
}