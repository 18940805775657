@import "../../styles/common";

.calendar-datepicker {
  .react-datepicker {
    &__header {
      background-color: transparent;
      border: none;
    }

    &__day-name {
      color: $ct-gray-650;
      text-transform: capitalize;
    }

    &__current-month {
      font-weight: normal;
      padding-bottom: 10px;
      text-transform: capitalize;
    }

    &__navigation:focus {
      outline: none;
    }

    &__month-container {
      + .react-datepicker__month-container {
        margin-left: 10px;
      }
    }

    &__day {
      &--outside-month {
        color: $ct-gray-650;
      }

      &--selected {
        background-color: var(--primary);

        &:hover {
          background-color: var(--primary);
        }
    }
      &--in-range {
        background-color: var(--primary);

        &:hover {
          background-color: var(--primary);
        }
      }

      &--in-selecting-range {
        background-color: color-mix(in srgb, var(--primary), transparent 75%);

        &:hover {
          background-color: var(--primary);
        }
      }

      &--keyboard-selected {
        background-color: var(--primary);

        &:hover {
          background-color: var(--primary);
        }
      }
    }

    &__navigation {
      border: 1px solid transparent;
      width: 8px;
      height: 8px;
      border-top-color: $ct-black-50;
      border-right-color: $ct-black-50;
      top: 15px;

      &--next {
        transform: rotate(45deg);

        &:hover {
          border-left-color: transparent;
        }
      }

      &--previous {
        transform: rotate(-135deg);

        &:hover {
          border-right-color: $ct-black-50;
        }
      }
    }
  }
}