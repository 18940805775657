@use "../../styles/common";

.scrollToTopButton {
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: common.$knowledge-base-main-text;
    width: 36px;
    height: 36px; 
    border-radius: 50%;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
    background-color: common.$main-container-background;

    &_disabled {
        pointer-events: none;
    }

    &_hidden {
        display: none;
    }

    &:hover, 
    &:active,
    &:focus {
      color: var(--primary);
    }
}